<template>
    <div id="NavBar" class="w-full h-16 bg-sky-950 fixed top-0 left-0 z-10">
        <!--Nav Bar Container-->
        <div class="navBarContainer container h-full text-white flex justify-between items-center">
            <!-- Logo -->
            <router-link to="/" class="flex items-center gap-2 md:basis-1/5" @click="displayMobileMenu = false">
                <div class="logoContainer h-10">
                    <img src="/assets/img/logo_horizontal_letra_blanco.png" alt="LOGO" class="h-full">
                </div>
            </router-link>
            <!-- Menu -->
            <nav id="nav" class="basis-3/5 justify-center hidden md:flex">
                <ul class="flex items-center gap-8 text-sm">
                    <li v-for="option, index in menuOptions" :key="index">
                        <router-link class="h-full px-2 flex items-center gap-2 opacity-50 hover:opacity-100"
                            :to="option.link" v-if="option.subOptions.length == 0">
                            <Icon :iconImg="option.icon" class="w-5 bg-white"></Icon>
                            <p>{{ option.label }}</p>
                        </router-link>
                        <Dropdown :label="option.label" :icon="option.icon" :options="option.subOptions" mode="floating"
                            v-else></Dropdown>
                    </li>
                </ul>
            </nav>
            <!-- Search Bar -->
            <div class="searchInput basis-1/5 hidden md:block">
                <input type="text" placeholder="Buscar"
                    class="w-full py-2 text-sm bg-transparent focus:outline-none border-b border-b-white border-opacity-50">
            </div>
            <!-- Mobile menu -->
            <div class="mobileMenu w-7 rounded-lg md:hidden" @click="displayMobileMenu = !displayMobileMenu">
                <Icon :iconImg="displayMobileMenu ? '/assets/icons/close.svg' : '/assets/icons/menu.svg'"
                    class="w-full bg-white"></Icon>
            </div>
        </div>
        <!--Mobile menu-->
        <div id="mobileMenu" class="fixed top-16 bottom-0 w-full min-h-screen bg-sky-950 text-white transition-all duration-500 ease-in-out"
            :class="displayMobileMenu ? 'right-0' : '-right-full'">
            <!-- Menu -->
            <nav id="mobileNav" class="w-full container pt-4">
                <ul class="space-y-6">
                    <li v-for="option, index in menuOptions" :key="index">
                        <router-link class="h-full flex items-center gap-2 opacity-50 hover:opacity-100"
                            :to="option.link" v-if="option.subOptions.length == 0" @click="displayMobileMenu = false">
                            <Icon :iconImg="option.icon" class="w-6 bg-white"></Icon>
                            <p>{{ option.label }}</p>
                        </router-link>
                        <Dropdown :label="option.label" :icon="option.icon" :options="option.subOptions" @optionClicked="displayMobileMenu = false" v-else>
                        </Dropdown>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
//Components
import Icon from './Icon.vue';
import Dropdown from './Dropdown.vue';

//This component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavBar',
    props: {
        menuOptions: [] as any[any]
    },
    components: {
        Icon,
        Dropdown
    },
    data() {
        return {
            displayMobileMenu: false
        }
    },
    methods: {
        toggleMobileMenu(status: boolean) {
            this.displayMobileMenu = status
        }
    }
})
</script>

<style>
#mobileMenu {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
}
</style>