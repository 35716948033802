<template>
  <NavBar :menuOptions="menuOptions"></NavBar>
  <router-view class="text-sm mt-16" />
  <footer class="bg-sky-950 p-8 text-white text-center">
    <div id="footerContainer" class="w-full md:w-1/2 flex flex-col gap-4 items-center mx-auto">
      <div id="text" class="text-xs leading-relaxed">
        <p>¿Cansado de buscar información educativa en múltiples lugares?<br class="hidden md:block">
          Nosotros también lo estuvimos. Por eso, en CyberPilots, junto con la Comisión de Educación de Rio Negro,
          creamos una plataforma que centraliza toda la oferta educativa de la región, tanto formal como no formal.<br
            class="hidden md:block">
          ¡Encuentra lo que necesitas de manera sencilla y rápida!</p>
      </div>
      <div id="logoCyberPilots" class="opacity-30 flex flex-col items-center">
        <p class="text-xs">Desarrollador por</p>
        <img src="/assets/img/logo_horizontal_blanco.svg" alt="" class="w-1/3 md:w-1/4">
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
//Components
import NavBar from './components/NavBar.vue';

//This component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar
  },
  data() {
    return {
      menuOptions: [
        {
          icon: '/assets/icons/home.svg',
          label: 'Inicio',
          link: '/',
          subOptions: []
        },
        {
          icon: '/assets/icons/educative-offer.svg',
          label: 'Oferta educativa',
          link: '',
          subOptions: [
            {
              label: 'Todas',
              link: '/oferta-educativa'
            },
            {
              label: 'Educación primaria',
              link: '/oferta-educativa'
            },
            {
              label: 'Educación secundaria',
              link: '/oferta-educativa'
            },
            {
              label: 'Universitaria',
              link: '/oferta-educativa'
            },
            {
              label: 'Terciaria no universitaria',
              link: '/oferta-educativa'
            },
            {
              label: 'Educación no formal',
              link: '/oferta-educativa'
            }
          ]
        },
        {
          icon: '/assets/icons/institute.svg',
          label: 'Instituciones',
          link: '',
          subOptions: [
            {
              label: 'Todas',
              link: '/instituciones'
            },
            {
              label: 'Educación primaria',
              link: '/instituciones'
            },
            {
              label: 'Educación secundaria',
              link: '/instituciones'
            },
            {
              label: 'Universitaria',
              link: '/instituciones'
            },
            {
              label: 'Terciaria no universitaria',
              link: '/instituciones'
            },
            {
              label: 'Educación no formal',
              link: '/instituciones'
            }
          ]
        },
      ]
    }
  }
})
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>