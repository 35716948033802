<template>
  <div id="home">
    <!-- Main banner -->
    <section id="mainBanner"
      class="h-[calc(100vh_-_64px)] overflow-hidden relative">
      <!--div id="backgroud" class="w-full h-full absolute top-0 left-0 opacity-70">
        <img src="/assets/img/backgroud-home4.jpg" alt="Fondo" class="w-full h-full object-center object-cover">
      </div-->
      <video src="/assets/bg-video.mp4" autoplay muted loop class="absolute left-0 top-0 w-full h-full object-cover opacity-25"></video>
      <div class="tagCloudContainer h-full"></div>
      <div class="absolute left-0 top-0 w-full h-full flex items-center justify-center text-center">
        <div class="typedTextContainer w-full md:w-1/2 aspect-square flex flex-col items-center justify-center">
          <p class="text-xl md:text-3xl font-bold text-sky-950">Conecta con tu</p>
          <p class="text-3xl md:text-5xl font-bold text-sky-600">próxim{{ typedText }}<span id="cursor" class="blinker">_</span></p>
        </div>
      </div>
    </section>
    <!-- Main content -->
    <section id="mainContent" class="container h-[calc(100vh_-_64px)] grid grid-cols-1 md:grid-cols-3 gap-8 py-16 ">
      <div id="card"
        class="h-full w-full bg-gray-300 rounded-lg overflow-hidden relative hover:scale-105 transition-all duration-300 ease-in-out"
        v-for="card, index in cardsInfo" :key="index" :class="index == 0 ? 'block' : 'hidden md:block'">
        <img class="w-full h-full object-cover object-center" :src="card.img" :alt="card.title">
        <div
          class="text absolute left-0 bottom-0 h-3/4 p-8 bg-gradient-to-b from-transparent to-sky-950 flex flex-col justify-end gap-4 text-white">
          <h6 class="text-2xl font-semibold">{{ card.title }}</h6>
          <p class="line-clamp-4">{{ card.description }}</p>
        </div>
      </div>
    </section>

    <!-- Institutions -->
    <section id="institutions">
      <div class="tab-arrow bg-sky-900 w-24 h-12 mx-auto pt-4 px-4 rounded-t-full text-white">
        <Icon iconImg="/assets/icons/chevron-down.svg" class="h-full mx-auto bg-white flex-none"></Icon>
      </div>
      <div class="InstitutionStats bg-sky-900 py-16 md:py-24">
        <div class="container space-y-16 md:space-y-24">
          <div class="numbers text-white font-bold flex flex-col md:flex-row md:justify-around gap-16 md:gap-0">
            <div class="numberContainer flex flex-col md:flex-row items-center justify-center gap-4">
              <Icon iconImg="/assets/icons/educative-offer.svg" class="w-20 md:w-28 bg-sky-600 flex-none mx-auto"></Icon>
              <div class="number text-center">
                <p class="text-6xl md:text-8xl">125</p>
                <p class="text-xl">Ofertas educativas</p>
              </div>
            </div>
            <div class="numberContainer flex flex-col md:flex-row items-center justify-center gap-4">
              <Icon iconImg="/assets/icons/institute.svg" class="w-20 md:w-28 bg-sky-600 flex-none mx-auto"></Icon>
              <div class="number text-center">
                <p class="text-6xl md:text-8xl">12</p>
                <p class="text-xl">Instituciones</p>
              </div>
            </div>
          </div>
          <div class="divider w-3/4 mx-auto border-b border-white border-opacity-10"></div>
          <div class="grid grid-cols-3 md:grid-cols-6 gap-4 md:gap-8">
            <div id="institution" class="w-full aspect-video bg-gray-100 rounded-lg p-4"
              v-for="institution, index in institutions" :key="index">
              <img class="w-full h-full object-contain object-center" :src="institution.logo" :alt="institution.name">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
//Component
import Icon from '@/components/Icon.vue';

//This component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Icon
  },
  data() {
    return {
      cardsInfo: [
        {
          title: 'Educación primaria',
          img: '/assets/img/primaria.jpg',
          description: 'Descubre las mejores instituciones educativas de nivel primario para tus hijos. Encuentra información detallada sobre las escuelas, su infraestructura, programas educativos y actividades extracurriculares diseñadas para el desarrollo integral de los más pequeños.'
        },
        {
          title: 'Educación secundaria',
          img: '/assets/img/secundaria.jpg',
          description: 'Explora las opciones de educación secundaria disponibles. Infórmate sobre las diferentes instituciones que ofrecen programas de secundaria, sus metodologías de enseñanza, orientación vocacional y actividades extracurriculares que preparan a los jóvenes para el futuro.'
        },
        {
          title: 'Universitario',
          img: '/assets/img/universidad.png',
          description: 'Conoce las universidades y las ofertas educativas disponibles para estudios superiores. Encuentra programas de grado, maestrías y doctorados, así como información sobre convenios internacionales y oportunidades de investigación que cada institución ofrece.'
        }
      ],
      institutions: [
        {
          name: 'Rio Negro',
          logo: '/assets/img/rio_negro.png'
        },
        {
          name: 'ANEP',
          logo: '/assets/img/anep.png'
        },
        {
          name: 'INEED',
          logo: '/assets/img/ineed.png'
        },
        {
          name: 'MEC',
          logo: '/assets/img/mec.png'
        },
        {
          name: 'UDELAR',
          logo: '/assets/img/udelar.png'
        },
        {
          name: 'UTEC',
          logo: '/assets/img/utec.png'
        }
      ],
      tags: [
        'Licenciatura en Tecnologías de la Información',
        'Ingeniería en Mecatrónica',
        'Enfermería',
        'Maquillaje',
        'Tecnicatura en electrónica',
        'Bachillerato Físico-Matemático',
        'Primaria Rural',
        'Ingeniería en Biomédica',
        'Primaria',
        'Bachillerato Humanístico',
        'Bachillerato Biológico',
        'Bachillerato Artístico',
        'Gestión de empresas',
        'Manicura',
        'Peluquería',
        'Ingeniería Logística',
        'Ingeniería Civil',
        'Ingeniería en Sistemas',
        'Licenciatura en Alimentos',
        'Ingeniería en Energías Renovables',
        'Ingeniería en Termodinámica'
      ],
      typedText: '',
      typedTextWords: [
        'a carrera',
        'o curso',
        'a capacitación',
        'o oficio',
        'a oportunidad',
        'o aprendizaje',
        'a experiencia',
        'o taller'
      ]
    }
  },
  mounted() {
    var tagCloud = (window as any).TagCloud('.tagCloudContainer', this.tags, {
      // radius in px
      radius: 600,

      // animation speed
      // slow, normal, fast
      maxSpeed: 'normal',
      initSpeed: 'normal',

      // 0 = top
      // 90 = left
      // 135 = right-bottom
      direction: 135,

      // interact with cursor move on mouse out
      keep: true
    });

    //type text effect
    this.typeText()

  },
  methods: {
    typeText() {
      let i = 0;
      let wordIndex = 0;
      let isDeleting = false;
      let speed = 100; // The speed/duration of the effect in milliseconds
      let pauseBetweenWords = 1000; // Pause before deleting in milliseconds

      const typeInterval = setInterval(() => {
        const currentWord = this.typedTextWords[wordIndex];

        if (!isDeleting) {
          this.typedText += currentWord.charAt(i);
          i++;
          if (i === currentWord.length) {
            setTimeout(() => {
              isDeleting = true;
            }, pauseBetweenWords);
          }
        } else {
          this.typedText = currentWord.substring(0, i - 1);
          i--;
          if (i === 0) {
            isDeleting = false;
            wordIndex = (wordIndex + 1) % this.typedTextWords.length;
            setTimeout(() => {
              isDeleting = false;
            }, pauseBetweenWords);
          }
        }
      }, speed);
    }
  }
});
</script>

<style>
.tagcloud {
  font-size: 14px;
  font-weight: 400;
  margin-left: 30%;
  margin: 0 auto;
  opacity: 25%;
  text-align: center;
  width: 100% !important;
  height: 100% !important;
}

.tagcloud--item {
  background: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid gray;
  box-shadow: 4px 4px 8px gray;
  opacity: 100% !important;
}

.tagcloud--item:hover {
  cursor: pointer;
  /*color: #36454F;*/
}

.blinker {
  opacity: 1;
  animation: blinker 0.9s steps(2, start) infinite;
}

@keyframes blinker {
  to { 
    visibility: hidden; 
  }
}

.typedTextContainer{
  background-image: radial-gradient(circle farthest-side at center, hsl(0, 0%, 100%) 0%, hsla(0, 0%, 100%, 0) 100%);
}
</style>
