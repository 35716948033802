import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/oferta-educativa',
    name: 'Ofertas educativas',
    component: () => import('../views/EducationOffer.vue')
  },
  {
    path: '/oferta-educativa/:id',
    name: 'Oferta educativa',
    component: () => import('../views/singles/EducationOfferSingle.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition){
    return new Promise((resolve, reject) => {
      if(savedPosition) {
        setTimeout(() => {
          return resolve(savedPosition)
        }, 0);
      }
      else {
        return resolve({ top: 0})
      }
    })
  }
})

export default router
